
import React, { useMemo, useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import './i18n'; // Import the i18n configuration

interface WelcomeModalProps {
  onClose: () => void;
}

const WelcomeModal: React.FC<WelcomeModalProps> = ({ onClose }) => {
  const { t } = useTranslation();

  const [code, setCode] = useState('');
  const [email, setEmail] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [thankYouMessage, setThankYouMessage] = useState('');

  const currentHostname = window.location.href;
  const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
  const linkUrl = currentHostname.includes(`${stagingKey}`) 
    ? `https://${stagingKey}.peaxel.me` 
    : 'https://peaxel.me';

  const handleCodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCode(e.target.value);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleTermsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTermsAccepted(e.target.checked);
  };

  const handleCodeSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (code === '01121999') {
      onClose();
    } else {
      alert(`${t('incorrectCode')}`);
    }
  };

  const handleEmailSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (termsAccepted && email) {
      try {
        const response = await axios.post(`${linkUrl}/wp-json/custom/v1/preregistration`, { email });
        if (response.status === 200) {
          setThankYouMessage(`${t('thankYouForPreRegistering')}! ${t('weWillNotifyYouWhenTheGameLaunches')}`);
        }
      } catch (error) {
        console.error('Error submitting email:', error);
      }
    } else {
      alert(`${t('pleaseAcceptTheTermsAndConditionsAndEnterYourEmail')}`);
    }
  };

  return (
    <div className="aroundWelcomeModal">

<button 
  className="close-button-modal1" 
  onClick={() => window.location.href=`${linkUrl}/`}>
  <i className="fa-regular fa-circle-xmark"></i>
</button>
        
      <div className="modal-container-welcome">
     
        <div className="welco1">
        <div className="welco11">
        <img src={`${linkUrl}/wp-content/uploads/2024/06/Peaxel_welcome_page.png`} alt="Welcome to Peaxel" />
         </div>
        <div className="welco12">
        {thankYouMessage ? (
          <p>{thankYouMessage}</p>
        ) : (
          <>
            <form onSubmit={handleEmailSubmit}>
              <input
                type="email"
                placeholder={t('enterYourEmail')}
                value={email}
                onChange={handleEmailChange}
                required
              />
              <div>
                <input
                  type="checkbox"
                  checked={termsAccepted}
                  onChange={handleTermsChange}
                  required
                />
                
                <label style={{ verticalAlign: 'middle', color: '#ffffffb0!important' }}>
  &nbsp;<a href={`${linkUrl}/peaxel-terms-and-conditions/`} target="_blank" style={{ color: '#343434' }}> I accept the terms and conditions - GTCU</a>
</label>

                  </div>
              <button type="submit">{t('notifyMe')}</button>
            </form>
          </>
        )}
        </div>
        </div>
        <div className="welco2">

        <form onSubmit={handleCodeSubmit}>
          <input
            type="text"
            placeholder="Secret key for Beta testing"
            value={code}
            onChange={handleCodeChange}
          />
          <button type="submit">{t('startPeaxel')}</button>
        </form>
        </div>
      </div>
    </div>
  );
};

export default WelcomeModal;
